<script setup>
   import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
   import { computed } from 'vue';

   const props = defineProps({
      width: {
         type: String,
         default: ' min-w-4/5  md:min-w-80',
      },
      contentSpace: {
         type: String,
         default: 'p-4',
      },
      locationClass: {
         type: String,
         default: 'bottom-full end-0 sm:end-1/2',
      },
      isBlack: {
         type: Boolean,
         default: false,
      },
      block: {
         type: Boolean,
         default: false,
      },
   });
   const popoverStyles = computed(() => {
      return {
         panel: `absolute z-10  mb-2  ${props.locationClass}  ${props.width}`,
         transition: {
            enter: 'transition duration-200 ease-out',
            enterFrom: 'translate-y-1 opacity-0',
            leave: 'transition duration-150 ease-in',
            leaveTo: 'translate-y-1 opacity-0',
         },
      };
   });
   const { locale } = useI18n();
</script>

<template>
   <Popover class="relative" :class="block ? 'w-full' : ''">
      <PopoverButton class="outline-none" :class="block ? 'w-full' : ''">
         <slot />
      </PopoverButton>

      <transition
         :enter-active-class="popoverStyles.transition.enter"
         :enter-from-class="popoverStyles.transition.enterFrom"
         :leave-active-class="popoverStyles.transition.leave"
         :leave-to-class="popoverStyles.transition.leaveTo"
      >
         <PopoverPanel
            :class="`${popoverStyles.panel} ${locale === 'ar' ? 'rtl' : 'ltr'}`"
         >
            <div
               class="overflow-auto rounded-lg shadow-lg ring-1 ring-black/5"
               :class="props.isBlack ? 'bg-gray-text text-white' : 'bg-white '"
            >
               <div :class="contentSpace">
                  <slot name="content"></slot>
               </div>
            </div>
         </PopoverPanel>
      </transition>
   </Popover>
</template>
